<template>
  <PflegeDivider
    v-editable="props.blok"
    :hideOnMobile="props.blok.hideOnMobile"
    :icon="props.blok.icon"
    borderColor="neutral-30"
    height="4rem"
    :data-uid="props.blok._uid"
  />
</template>

<script setup>
import { PflegeDivider } from '@shared'

const props = defineProps({
  blok: {
    type: Object,
    required: true
  }
})
</script>
